import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  gap: 1.25rem;
`;

type DateRangeSelectorProps = {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
};

export default function DateRangeSelector({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  maxDate,
  disabled,
}: DateRangeSelectorProps) {
  const [open, setOpen] = useState(true);
  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          onClose={() => setOpen(false)}
          open={open && !disabled}
          onOpen={() => setOpen(true)}
          label="Start date"
          value={dayjs(startDate)}
          onChange={(newValue: Dayjs | null) => {
            if (newValue) {
              setStartDate(newValue.toISOString());
            }
          }}
          minDate={dayjs(startDate)}
          maxDate={dayjs(maxDate) || dayjs(maxDate)}
        />
        <DatePicker
          disabled={disabled}
          label="End date"
          value={dayjs(endDate)}
          onChange={(newValue: Dayjs | null) => {
            if (newValue) {
              setEndDate(newValue.toISOString());
            }
          }}
          minDate={dayjs(startDate).add(1, "day")}
          maxDate={dayjs(maxDate).add(1, "day")}
        />
      </LocalizationProvider>
    </Wrapper>
  );
}
