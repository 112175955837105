import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../constants";
import CardView from "./CardView";
import { LoadingAnimation } from "../subcomponents/LoadingAnimation";
import PCTableView from "../PrivateCatalog/Cards/PCTableView";
import { ListTogglerValue } from "../subcomponents/CardFilterHeader/tableListToggler";
import {
  CatalogResultsContainer,
  CardViewWrapper,
  Wrapper,
  UtilContainer,
} from "./Catalog.styles";
import { CardFilterHeader } from "../subcomponents/CardFilterHeader";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { PageHeader } from "../../../global.styles";
import usePostEventCollection from "src/hooks/usePostEventCollection";

const CatalogResults = ({
  inputValue,
  shouldAnimate,
  visibleData,
  handleNavigateTo,
  toggleValue,
}) => {
  return (
    <CatalogResultsContainer>
      <CardViewWrapper shouldAnimate={shouldAnimate}>
        {toggleValue === "list" && (
          <CardView
            variant="simple"
            data={visibleData}
            handleNavigateTo={handleNavigateTo}
            searchValue={inputValue}
            minMax="18.75rem"
          />
        )}
        {toggleValue === "table" && (
          <PCTableView
            searchValue={inputValue}
            catalogs={visibleData}
            handleNavigateTo={(data: { serviceName }) => {
              const { serviceName } = data;
              handleNavigateTo(serviceName);
            }}
          />
        )}
      </CardViewWrapper>
    </CatalogResultsContainer>
  );
};

type PublicCatalogProps = {
  shouldAnimate?: boolean;
  setActiveMenuItem?: (item: string) => void;
  initCatalogs?: any[];
  handleBreadCrumbNickName: (name: string) => void;
};

const PublicCatalog = ({
  shouldAnimate,
  setActiveMenuItem,
  initCatalogs,
}: PublicCatalogProps) => {
  const [catalogs, setCatalogs] = useState<any>(initCatalogs ?? []);
  const searchValue = "";
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [toggleValue, setToggleValue] = useState("list");

  useEffect(() => {
    setCatalogs(initCatalogs ?? []);
  }, [initCatalogs]);

  useEffect(() => {
    if (setActiveMenuItem) {
      setActiveMenuItem("Dashboard");
    }
  }, [setActiveMenuItem]);

  useEffect(() => {
    if (!initCatalogs) {
      setIsLoading(true);
      fetch(`${baseUrl}/api/catalog`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setCatalogs(data);
          setHasError(false);
        })
        .catch(() => setHasError(true))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const filtered = catalogs.filter((item: { serviceName: string }) =>
    item.serviceName.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const navigate = useNavigate();

  const { postEvent } = usePostEventCollection("catalog-click");

  const setAndNavigate = (nameOfCard, idOfCard, url) => {
    let data = {
      serviceId: idOfCard,
      serviceName: nameOfCard,
      displayOrder: idOfCard,
    };

    postEvent(data);
    navigate(url);
  };

  const handleNavigateTo = (name: string, id: number) => {
    if (id === 14) {
      setAndNavigate(
        name,
        id,
        "/public-catalog/ss&c-algorithmics-risk-reports",
      );
    }
    if (id === 2) {
      setAndNavigate(
        name,
        id,
        "/public-catalog/ss&c-algorithmics-performance-reports",
      );
    }
    if (id === 3) {
      setAndNavigate(name, id, "/public-catalog/ss&c-chorus-everywhere");
    }
    if (id === 18) {
      setAndNavigate(name, id, "/public-catalog/ss&c-tax-brightline");
    }
    if (id === 4) {
      setAndNavigate(name, id, "/public-catalog/tableau");
    }
    if (id === 5) {
      setAndNavigate(name, id, "/public-catalog/kafka");
    }
    if (id === 10) {
      setAndNavigate(name, id, "/public-catalog/msftsql");
    }
    if (id === 6) {
      setAndNavigate(name, id, "/public-catalog/tarvos");
    }
    if (id === 7) {
      setAndNavigate(name, id, "/public-catalog/nifi");
    }
    if (id === 9) {
      setAndNavigate(name, id, "/public-catalog/oracle");
    }
    if (id === 16) {
      setAndNavigate(name, id, "/public-catalog/ss&c-sightline");
    }
    if (id === 1) {
      setAndNavigate(name, id, "/public-catalog/data-mesh");
    }
    if (id === 15) {
      setAndNavigate(name, id, "/public-catalog/apache-superset");
    }
    if (id === 11) {
      setAndNavigate(name, id, "/public-catalog/postgresql");
    }
    if (id === 12) {
      setAndNavigate(name, id, "/public-catalog/powerbi");
    }
    if (id === 20) {
      setAndNavigate(
        name,
        id,
        "/public-catalog/ss&c-risk-&-performance-service",
      );
    }
    if (id === 8) {
      // api is sending L instead of I for .AI, will request a change
      // handleBreadCrumbNickName("Riskthinking.AI");
      setAndNavigate(name, id, "/public-catalog/riskthinking");
    }
  };

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingAnimation />
      </Wrapper>
    );
  }

  const handleToggle = (toggleValue: ListTogglerValue) => {
    setToggleValue(toggleValue);
  };

  return (
    <Wrapper>
      <PageHeader>Public Catalog</PageHeader>
      {hasError && <ErrorNotification />}
      <UtilContainer>
        <CardFilterHeader
          toggleValue={toggleValue}
          searchValue={value}
          handleToggleChange={handleToggle}
          handleChangeForSearch={(e) => setValue(e.target.value)}
        />
      </UtilContainer>

      <CatalogResults
        toggleValue={toggleValue}
        inputValue={value}
        shouldAnimate={shouldAnimate}
        visibleData={filtered}
        handleNavigateTo={handleNavigateTo}
      />
    </Wrapper>
  );
};

export default PublicCatalog;
