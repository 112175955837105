import styled from "styled-components";

export const Wrapper = styled.section`
  padding-top: 2vh;
`;

export const Nav = styled.nav`
  height: 100%;
  position: fixed;
  padding-top: 1rem;
  width: 17vw;
  bottom: -10rem;
  left: 0;
  z-index: 4;
  background-color: var(--neutralBackground);
  cursor: pointer;
  padding-top: 10rem;
`;

export const NavItem = styled.div`
  padding: 0;
  color: var(--neutralBlack);
  text-decoration: none;
  margin: 0 1.5rem;
  padding: 0.938rem;
  border-bottom: 2px solid var(--neutral300);

  &:hover {
    background-color: var(--neutral300);
  }

  &:last-child {
    border-bottom: none;
  }
  @media only screen and (max-width: 1240px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
