import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import CatalogAlgoRiskCarousle from "./CatalogAlgoriskCarousle";
import "./CatalogAlgoRiskReport.css";
import CardView from "../CardView";
import { Button } from "@ssce/ui-shared-library";
import TuneIcon from "@mui/icons-material/Tune";
import BalanceIcon from "@mui/icons-material/Balance";
import GradingIcon from "@mui/icons-material/Grading";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CatalogBenefits from "../CatalogBenefits";

const CARD_DATA = [
  {
    catalogId: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    catalogId: 2,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/SS%26C_Technologies_logo.svg/1200px-SS%26C_Technologies_logo.svg.png",
    serviceName: "SS&C Sightline",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Unlock the full potential of your data with a comprehensive data management platform that aggregates inbound data sources, applies a user-defined enrichment and validation routine and then distributes a defined dataset.",
    plan: "Free Trial Available",
    tags: {
      function: "BI",
      industry: "Cross-Industry",
      serviceType: "Data & Analytics",
    },
  },
];

const CatalogAlgoRiskOverview: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (_, id) => {
    if (id === 1) {
      return navigate("/public-catalog/data-mesh");
    }
    if (id === 2) {
      return navigate("/public-catalog/ss&c-sightline-everywhere");
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "3rem", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
          <Typography
            variant="h4"
            fontSize="1.5rem"
            fontWeight={600}
            fontStyle={"italic"}
            component="div"
            style={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Powered by SS&C Algorithmics
          </Typography>
          <div>
            <div
              style={{
                display: "flex",
                padding: "0.625rem",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                component={"div"}
                style={{ color: "#000", lineHeight: "1.5rem" }}
              >
                <p>
                  The SS&C Risk Service is an outsourced service that can be
                  easily appended to an existing SS&C agreement such as GlobeOp,
                  Fund Admin, Geneva, EZE and Aloha. ​
                </p>
                <p>
                  The Risk Service uses existing holdings data housed within the
                  SS&C platform to deliver sophisticated risk analytics. Risk
                  metrics include VaR, stress tests, sensitivities, exposures
                  and valuations through reports or an interactive and intuitive
                  web-based dashboard. ​
                </p>
                <p>
                  The Service also sources required market data such as
                  instrument terms and conditions and interest rate curves to
                  complete modeling and produce accurate outputs. Clients
                  benefit from sophisticated calculations, often beyond the
                  capabilities of in-house teams, via a cost-effective service.​
                </p>
              </Typography>
            </div>
            <CatalogAlgoRiskCarousle />
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <TuneIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Seamless Integration",
              description:
                "Achieve smooth integration with SS&C systems or other platforms​",
            },
            {
              icon: (
                <FilterListIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Customized",
              description:
                "Tailor the solution to meet your specific needs and objectives​​",
            },
            {
              icon: (
                <BalanceIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Balance BAU and Projects",
              description:
                "We manage BAU tasks, allowing you to concentrate on value-creating projects ​",
            },
            {
              icon: (
                <GradingIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Data Quality",
              description:
                "Improves accuracy and reliability, essential for effective performance analysis​",
            },
            {
              icon: (
                <StackedLineChartIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Advanced Modeling",
              description:
                "Employs highly sophisticated quantitative models for precise risk management, helping you optimize your risk-reward profiles.​​",
            },
            {
              icon: (
                <GppMaybeIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Compliance and Regulatory Reporting",
              description:
                "Supports compliance with regulatory frameworks, ensuring your business meets capital adequacy requirements while delivering accurate and timely regulatory reports.​​",
            },
            {
              icon: (
                <AccountTreeIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Streamlined Processes",
              description:
                "Boosts operational efficiency by integrating seamlessly with SS&C’s systems and third-party platforms, ensuring a unified technological ecosystem​",
            },
            {
              icon: (
                <SupportAgentIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Dedicated Support",
              description:
                "Comprehensive client support and consulting services​",
            },
          ]}
        />

        {/* TODO: Move the following commented out code to shared repo */}

        {/* <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
          >
            Available Pricing Plans
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "3rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "1.813rem" }}>
              <Card
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "1.25rem",
                  gap: "2.625rem",
                  padding: "0.625rem 0",
                  flex: "100%",
                  flexWrap: "wrap",
                  boxShadow: "none",
                  position: "relative",
                }}
              >
                <Card
                  sx={{
                    ":hover": { bgcolor: "#C0EBF8" },
                    width: "18.438rem",
                    padding: "1.5rem",
                    boxShadow:
                      "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                    border: "0.063rem solid #CBD9E1",
                    borderRadius: "0.25rem",
                    paddingBottom: "0.625rem",
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 auto",
                    alignSelf: "self-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "80%",
                      marginTop: "1.25rem",
                    }}
                  >
                    <img
                      src={Users}
                      alt="SS&C logo"
                      style={{
                        height: "1.25rem",
                        width: "1.813rem",
                        marginRight: "0.625rem",
                        marginLeft: "1.875rem",
                        marginTop: "0.5rem",
                      }}
                    ></img>
                    <div>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        align="left"
                        fontWeight={500}
                        lineHeight={1}
                        style={{
                          fontSize: "2.125rem",
                          display: "inline-block",
                        }}
                      >
                        Standard
                      </Typography>
                    </div>
                  </div>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    align="left"
                    fontWeight={500}
                    lineHeight={1.5}
                    style={{
                      marginTop: "0",
                      fontSize: "1rem",
                      marginLeft: "1.875rem",
                    }}
                  >
                    Perfect for getting started
                  </Typography>
                  <List>
                    <ListItem>
                      <img
                        src={CheckCircle}
                        alt="CSV Risk Report."
                        style={{ marginRight: "0.625rem" }}
                      ></img>
                      <Typography
                        variant="body1"
                        fontSize="0.75rem"
                        fontWeight={500}
                        lineHeight={1}
                      >
                        CSV Risk Reports
                      </Typography>
                    </ListItem>
                  </List>
                </Card>

                <Card
                  sx={{
                    ":hover": {
                      bgcolor: "#C0EBF8",
                    },
                    width: "18.438rem",
                    padding: "1.5rem",
                    boxShadow:
                      "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                    border: "0.063rem solid #CBD9E1",
                    borderRadius: "0.25rem",
                    paddingBottom: "0.625rem",
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 auto",
                    alignSelf: "self-end",
                  }}
                >
                  <div>
                    <Chip
                      label="Popular"
                      sx={{
                        "&": {
                          position: "absolute",
                          top: 0,
                          marginLeft: "10rem",
                          padding: "0.25rem 0.625rem",
                          fontFamily: "Roboto Medium",
                          color: "#F6FDFF",
                          fontSize: "0.75rem",
                          backgroundColor: "#3CC13B",
                        },
                      }}
                      color="success"
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: "1.25rem" }}>
                    <img
                      src={Users}
                      alt="SS&C logo2"
                      style={{
                        height: "1.25rem",
                        width: "1.813rem",
                        marginRight: "0.625rem",
                        marginLeft: "0.625rem",
                        marginTop: "0.5rem",
                      }}
                    ></img>
                    <div>
                      <Typography
                        gutterBottom
                        fontWeight={600}
                        lineHeight={1}
                        style={{
                          fontSize: "2.125rem",
                          display: "inline-block",
                        }}
                      >
                        Premium
                      </Typography>
                    </div>
                  </div>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    align="left"
                    fontWeight={500}
                    lineHeight={1.5}
                    style={{
                      marginTop: "0",
                      fontSize: "1rem",
                      marginLeft: "1.875rem",
                    }}
                  >
                    Perfect for getting started
                  </Typography>
                  <List>
                    <ListItem>
                      <img
                        src={CheckCircle}
                        alt="CSV Risk Report."
                        style={{ marginRight: "0.625rem" }}
                      ></img>
                      <Typography
                        variant="body1"
                        fontSize="0.75rem"
                        fontWeight={500}
                        lineHeight={1}
                      >
                        CSV Risk Reports
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img
                        src={CheckCircle}
                        alt="Risk Dashboard Access"
                        style={{ marginRight: "0.625rem" }}
                      ></img>
                      <Typography
                        variant="body1"
                        fontSize="0.75rem"
                        fontWeight={500}
                        lineHeight={1}
                      >
                        Risk Dashboard Access
                      </Typography>
                    </ListItem>
                  </List>
                </Card>
              </Card>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  fontWeight: "500",
                  fontSize: "1.25rem",
                  fontFamily: "Roboto Medium",
                }}
              >
                View Full Pricing Information
              </Button>
            </div>
          </div>
        </div> */}
        {/* <div
          style={{
            display: "flex",
            gap: "2rem",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
          >
            Region Availability
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
            >
              <Typography
                variant="h6"
                fontSize="1.25rem"
                fontWeight={600}
                component="div"
                style={{ color: "#02273C", lineHeight: "2.25rem" }}
              >
                Available Regions
              </Typography>
              <div style={{ display: "flex", gap: "2.188rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "20.438rem",
                  }}
                >
                  <ul>
                    <li
                      style={{
                        color: "#577687",
                        fontSize: "1rem",
                        padding: "0.5rem 0.313rem",
                      }}
                    >
                      North America
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "20.438rem",
                  }}
                ></div>
                <div style={{}}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "1.5rem",
                flexDirection: "column",
                padding: "1rem 1.5rem",
                backgroundColor: "#EDF4F8",
                border: "0.063rem solid #CBD9E1",
                borderRadius: "0.25rem",
                width: "97%",
              }}
            >
              <Typography
                variant="h6"
                fontSize="1.25rem"
                fontWeight={600}
                component="div"
                style={{ color: "#02273C", lineHeight: "2.25rem" }}
              >
                Specific Region Details
              </Typography>
              <div style={{ display: "flex", gap: "2.813rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "20.438rem",
                  }}
                >
                  <Region
                    regionList={{
                      NorthAmerica: "/api/assets/NorthAmerica.png",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={700}
                      component="div"
                      style={{
                        color: "#02273C",
                        lineHeight: "1.5rem",
                        paddingBottom: 8,
                      }}
                    >
                      Region Details
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      style={{ color: "#577687", lineHeight: "1.5rem" }}
                    >
                      North America: This region provides low-latency access to
                      customers located in the United States and Canada. It
                      offers high availability and scalability, making it an
                      ideal choice for enterprise customers with large-scale
                      workloads.
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={700}
                      component="div"
                      style={{
                        color: "#02273C",
                        lineHeight: "1.5rem",
                        paddingBottom: 8,
                      }}
                    >
                      Local Compliance
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      style={{ color: "#577687", lineHeight: "1.5rem" }}
                    >
                      Fully compliant with regulatory requirements, including:
                      <ul>
                        <li>GDPR</li>
                        <li>ISO 27000</li>
                        <li>SOC-2</li>
                      </ul>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            gap: "2rem",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            style={{ color: "#0A85C7", lineHeight: "1.875rem" }}
          >
            Related Products
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <CardView
              variant="simple"
              data={CARD_DATA}
              handleNavigateTo={handleNavigateTo}
            />

            <Button
              variant="text"
              id={"viewMore"}
              onClick={() => {
                navigate("/public-catalog");
              }}
            >
              View More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogAlgoRiskOverview;
