import { useEffect, useState } from "react";
import { formatDate } from "./formatDate";
import { LoginUserData, UniqueUserEventData, DateTime } from "../Types/types";
import useFetch from "./useFetch";

type UserObj = {
  name: string;
  domain: string;
  count: number;
  latestLogin: string;
  firstLogin: string;
  position: number;
};

const findFirstLogin = (
  userToFind: string,
  fetchedLoginData: LoginUserData[],
) => {
  const latestLogin = fetchedLoginData
    ?.reverse()
    .find((x) => x.user === userToFind);
  if (latestLogin) return latestLogin.created_at;
  return "1971-01-01T00:00:00Z";
};

const findLatestLogin = (
  userToFind: string,
  fetchedLoginData: LoginUserData[],
) => {
  const firstLogin = fetchedLoginData?.find((x) => x.user === userToFind);
  if (firstLogin) return firstLogin.created_at;
  return "1970-01-01T00:00:00Z";
};

const useFetchTopUsers = ({ startDate, endDate }: DateTime) => {
  const [data, setData] = useState<UserObj[]>([]);
  const [rawData, setRAWData] = useState<UserObj[]>([]);

  const endpoint =
    startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : "";

  const { data: fetchedUniqueUserLoginData } = useFetch<UniqueUserEventData>(
    "user-pie?events=login" + endpoint,
  );

  const { data: fetchedLoginData } = useFetch<LoginUserData[]>("logins");

  useEffect(() => {
    if (!fetchedUniqueUserLoginData) return;
    const uniqueUserLoginArr = Object.entries(fetchedUniqueUserLoginData);
    const arr = uniqueUserLoginArr.map(([email, count], i) => {
      return {
        name: email.split("@")[0],
        domain: email.split("@")[1],
        count,
        latestLogin:
          fetchedLoginData &&
          formatDate(findLatestLogin(email, fetchedLoginData)),
        firstLogin:
          fetchedLoginData &&
          formatDate(findFirstLogin(email, fetchedLoginData)),
        position: i + 1,
      };
    }) as UserObj[];
    const slicedArr = arr.slice(0, 5);
    const reversedArr = [...slicedArr].reverse();
    setData(reversedArr);
    setRAWData(arr);
  }, [fetchedUniqueUserLoginData, fetchedLoginData]);

  return { data, rawData };
};

export default useFetchTopUsers;
