import { useState, useEffect } from "react";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useFetch from "../Utils/useFetch";
import { formatDate } from "../Utils/formatDate";
import { Flex } from "../Styles/Styles";
import { Card } from "../Components";
import DateRangeSelector from "../Components/DatePicker";
import {
  HeatMap,
  SessionsByPlatformPieChart,
  TopUsersList,
  UniqueUsersBarChart,
} from "../Charts";
import {
  UniqueUserEventData,
  FetchedActiveUserData,
  FetchedLoginData,
  LoginsOverTimeData,
  LoginUserData,
  ActiveUserData,
  DAUData,
} from "../Types/types";

type FetchedDAUData = {
  date: string;
  count: number;
}[];

type DateItem = {
  date?: string;
  count?: number;
}[];

const UserAnalyticsContainer = styled.div`
  margin: 0 6.25rem;
`;

const LeftSection = styled.div`
  width: max-content;
`;

const RightSection = styled.div`
  width: 100%;
  justify-content: right;
  display: flex;
  gap: 2.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

const TabsShelf = styled.div`
  width: calc(100% + 20px);
  padding-right: 20px;
`;

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
const formattedYesterdayDate = yesterday.toISOString();

const isNewOrReturningUserCount = (userInfo: UniqueUserEventData) => {
  const usersWhoLoggedInMoreThanOnce = userInfo?.users?.filter(
    (user) => user.count > 1,
  );
  return usersWhoLoggedInMoreThanOnce?.length ?? 0;
};

export default function UserAnalytics() {
  const [value, setValue] = useState(600);
  const [startDate, setStartDate] = useState<string>("2024-09-13T00:00:00Z");
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [earliestPossibleStartDate, setEarliestPossibleStartDate] = useState(
    "2024-09-13T00:00:00Z",
  );
  const [data, setData] = useState<DateItem>([
    {
      date: "",
      count: 0,
    },
  ]);
  const [latestPossibleEndDate, setLatestPossibleEndDate] = useState(
    new Date().toISOString(),
  );

  const endpoint =
    startDate && endDate ? `?start_date=${startDate}&end_date=${endDate}` : "";

  const {
    data: fetchedDauData,
    loading: fetchedDauLoading,
    error: fetchedDauError,
  } = useFetch<FetchedDAUData>("dau" + endpoint);

  const endpointWithTodayAsStartAndEndDate = `start_date=${formattedYesterdayDate}`;
  const {
    data: fetchedLoginData,
    loading,
    error,
  } = useFetch<FetchedLoginData>("logins" + endpoint);
  const {
    data: fetchedUniqueUserData,
    loading: fetchedUniqueUserDataLoading,
    error: fetchedUniqueUserDataError,
  } = useFetch<UniqueUserEventData>("unique-users-events" + endpoint);

  const { data: fetchedActiveUserDataToday } = useFetch<FetchedActiveUserData>(
    "v1/active-users?time_interval=daily" +
      "&" +
      endpointWithTodayAsStartAndEndDate,
  );

  const { data: fetchedActiveUserData } = useFetch<DAUData[]>(
    "v1/active-users?time_interval=daily" +
      "&" +
      `start_date=${startDate}&end_date=${endDate}`,
  );

  const {
    data: fetchedHeatmapData,
    loading: fetchedHeatmapDataLoading,
    error: fetchedHeatmapDataError,
  } = useFetch<ActiveUserData[]>(
    "v1/active-users?time_interval=daily&" + endpoint,
  );

  const { data: loginsOverTimeData } = useFetch<LoginsOverTimeData>(
    "logins-over-time?" + endpointWithTodayAsStartAndEndDate,
  );

  const { data: fetchedLoginsData } = useFetch<LoginUserData[]>(
    "logins" + endpoint,
  );
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    const target = event.target as HTMLElement;
    const hasDynamicAmountOfDays = target.id;
    setDatesFromTodayToXDaysAgo(hasDynamicAmountOfDays || newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (!fetchedDauData) return;

    const earlyData = fetchedDauData[0]?.date ?? "";
    const lastDate = fetchedDauData[fetchedDauData.length - 1]?.date ?? "";

    setData(fetchedDauData);
    if (!startDate && !endDate) {
      setEarliestPossibleStartDate(earlyData);
      setLatestPossibleEndDate(lastDate);
    }
  }, [fetchedDauData, data, latestPossibleEndDate, startDate, endDate]);

  if (fetchedDauError) return <p>Error!</p>;
  if (fetchedDauLoading) return <p>Loading...</p>;

  if (error) return <p>Error!</p>;
  if (loading) return <p>Loading...</p>;

  const printDates =
    value === 1 || value === 7 || value === 30 || value === 90
      ? value + " Day" + (value === 1 ? "" : "s")
      : formatDate(startDate) + " - " + formatDate(endDate);

  const setDatesFromTodayToXDaysAgo = (x: number | string) => {
    const today = new Date();

    if (x === "custom") {
      setShowDatePicker(true);
      return;
    }

    if (x === "allTime") {
      const foreverAgo = new Date("2024-06-18T00:00:00Z");
      const formattedXDaysAgo = foreverAgo.toISOString();
      setEndDate(today.toISOString());
      setStartDate(formattedXDaysAgo);
      setShowDatePicker(false);
      return;
    }
    setShowDatePicker(false);
    const xDaysAgo = new Date(today);
    xDaysAgo.setDate(today.getDate() - Number(x));
    setEndDate(today.toISOString());
    setStartDate(xDaysAgo.toISOString());
  };
  return (
    <>
      <UserAnalyticsContainer>
        <Wrapper>
          <LeftSection>
            <TabsShelf>
              <Tabs
                value={value}
                sx={{ textTransform: "none" }}
                onChange={handleChange}
                aria-label="tabs"
              >
                <Tab value={1} label="1 Day" />
                <Tab value={7} label="7 Days" />
                <Tab value={30} label="30 Days" />
                <Tab value={90} label="90 Days" />
                <Tab value={365} label="Past Year" />
                <Tab value={600} id="allTime" label="All Time" />
                <Tab value={700} id="custom" label="Custom" />
              </Tabs>
            </TabsShelf>
          </LeftSection>
          <RightSection>
            <Card
              title={"Total Logins Today"}
              eyebrow={
                loginsOverTimeData ? Object.values(loginsOverTimeData)[0] : "0"
              }
              $secondary
            />
            <Card
              title={"Total Users Active Today"}
              eyebrow={
                (fetchedActiveUserDataToday &&
                  fetchedActiveUserDataToday[
                    fetchedActiveUserDataToday?.length - 1
                  ]?.count) ??
                "0"
              }
              $secondary
            />
          </RightSection>
        </Wrapper>
        <Flex $justifyContent="space-between">
          <h2>
            {formatDate(startDate)} - {formatDate(endDate)}{" "}
          </h2>
          {showDatePicker && (
            <DateRangeSelector
              disabled={!showDatePicker}
              minDate={earliestPossibleStartDate}
              maxDate={latestPossibleEndDate}
              startDate={earliestPossibleStartDate}
              setStartDate={setStartDate}
              endDate={latestPossibleEndDate}
              setEndDate={setEndDate}
            />
          )}
        </Flex>
        <Flex $gap={24} $justifyContent="space-between" $paddingBottom={24}>
          <Card
            title={"Unique Users"}
            eyebrow={fetchedUniqueUserData && fetchedUniqueUserData.user_count}
          />
          <Card title={"New Users"} eyebrow={`0`} />
          <Card
            title={"Returning Users"}
            eyebrow={
              fetchedUniqueUserData &&
              isNewOrReturningUserCount(fetchedUniqueUserData)
            }
          />
          <Card
            title={"Logins"}
            eyebrow={fetchedLoginData && fetchedLoginData?.length}
          />
        </Flex>
        <Flex $gap={24} $paddingBottom={24}>
          <Card
            title={"User logins"}
            subtitle={printDates}
            titlePaddingBottom={20}
          >
            {fetchedActiveUserData && (
              <UniqueUsersBarChart
                fetchedLoginsData={fetchedLoginsData ? fetchedLoginsData : []}
                fetchedActiveUserData={fetchedActiveUserData}
                isHourly={value === 1}
                loading={fetchedUniqueUserDataLoading}
                error={!!fetchedUniqueUserDataError}
              />
            )}
          </Card>
          <Card
            width={1000}
            title={"Sessions by Platform"}
            subtitle={printDates}
          >
            <SessionsByPlatformPieChart
              fetchedLoginsData={fetchedLoginsData ? fetchedLoginsData : []}
            />
          </Card>
        </Flex>
        <Flex>
          {" "}
          <Card
            graphHeight={"380px"}
            title={"User Activity"}
            subtitle={printDates}
            titlePaddingBottom={20}
          >
            <HeatMap
              fetchedLoginsData={fetchedLoginsData ? fetchedLoginsData : []}
              fetchedHeatmapData={fetchedHeatmapData ? fetchedHeatmapData : []}
              loading={fetchedHeatmapDataLoading}
              error={!!fetchedHeatmapDataError}
            />
          </Card>
        </Flex>
        <Flex
          $gap={24}
          $paddingTop={24}
          $paddingBottom={24}
          $justifyContent="space-between"
        >
          <Card title={"Top Users"} subtitle={printDates} graphHeight={"auto"}>
            <TopUsersList startDate={startDate} endDate={endDate} />
          </Card>
        </Flex>
      </UserAnalyticsContainer>
    </>
  );
}
