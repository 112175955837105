import { DateTime } from "../Types/types";
//@ts-ignore
import { useState, useEffect } from "react";
import styled from "styled-components";
import useFetchTopUsers from "../Utils/useFetchTopUsers";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Flex } from "../Styles/Styles";
import { Table } from "@ssce/ui-shared-library";

export type Direction = "asc" | "desc";

type Filter = {
  label: string;
  value: string;
};
export type Column = {
  id?: string;
  title?: string | JSX.Element;
  searchByValue?: (value: any, row: any) => any;
  dataIndex?: string;
  sorter?: boolean;
  width?: string;
  filters?: Filter[];
  defaultSortOrder?: Direction;
  showFilterOnLeft?: boolean;
  onFilter?: (filterValue: string, selectedData: any) => any;
  render?: (text: string, row: number, index: number) => any;
};

type DataRow = {
  id?: string;
  name: string;
  domain: string;
  count: number;
  firstLogin: string;
  latestLogin: string;
  position: number;
};

export type Paginator = {
  pageNumber: number;
  rowsPerPage: number;
  onPaginate: (pageNumber) => void;
  onRowsPerChange: (newRowsPerPage: string) => void;
};

const Chip = styled.div`
  background-color: var(--azure75);
  color: var(--primary);
  border-radius: 9999px;
  padding: 0 0.5rem;
  height: 1.5rem;
  text-decoration: none;
`;

const alphabetObjArr = [
  { label: "A", value: "a" },
  { label: "B", value: "b" },
  { label: "C", value: "c" },
  { label: "D", value: "d" },
  { label: "E", value: "e" },
  { label: "F", value: "f" },
  { label: "G", value: "g" },
  { label: "H", value: "h" },
  { label: "I", value: "i" },
  { label: "J", value: "j" },
  { label: "K", value: "k" },
  { label: "L", value: "l" },
  { label: "M", value: "m" },
  { label: "N", value: "n" },
  { label: "O", value: "o" },
  { label: "P", value: "p" },
  { label: "Q", value: "q" },
  { label: "R", value: "r" },
  { label: "S", value: "s" },
  { label: "T", value: "t" },
  { label: "U", value: "u" },
  { label: "V", value: "v" },
  { label: "W", value: "w" },
  { label: "X", value: "x" },
  { label: "Y", value: "y" },
  { label: "Z", value: "z" },
];

export default function TopUsersList({ startDate, endDate }: DateTime) {
  const [onlyDisplayTopFive, setOnlyDisplayTopFive] = useState(true);
  const { data, rawData } = useFetchTopUsers({ startDate, endDate });
  const handleChange = (_: React.SyntheticEvent, tabsValue: number) => {
    if (tabsValue === 1 || data.length < 5) {
      setOnlyDisplayTopFive(true);
    }
    if (tabsValue === 2) {
      setOnlyDisplayTopFive(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      filters: alphabetObjArr,
      width: "200px",
      render: (text, { position }) => {
        return (
          <Flex $gap={20}>
            <Chip> {position}</Chip>
            <b>{text}</b>
          </Flex>
        );
      },
      onFilter: (value: string, data: DataRow) =>
        data.name[0]?.toLowerCase() === value,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      sorter: true,
      filters: alphabetObjArr,
      onFilter: (value: string, data: DataRow) =>
        data.domain[0]?.toLowerCase() === value,
    },
    {
      title: "Logins",
      dataIndex: "count",
      showFilterOnLeft: true,
      sorter: true,
      defaultSortOrder: "desc" as Direction,
      filters: [
        {
          label: "5+",
          value: "5",
        },
        {
          label: "10+",
          value: "10",
        },

        {
          label: "25+",
          value: "25",
        },
        {
          label: "50+",
          value: "50",
        },
        {
          label: "100+",
          value: "100",
        },
      ],
      onFilter: (value: string, data: DataRow) =>
        data.count >= Number(value) ? data.count : null,
    },
    {
      title: "First Login",
      dataIndex: "firstLogin",
      sorter: false,
      filters: alphabetObjArr,
      onFilter: (value: string, data: DataRow) =>
        data.firstLogin[0]?.toLowerCase() === value,
    },
    {
      title: "Last Login",
      dataIndex: "latestLogin",
      sorter: false,
      filters: alphabetObjArr,
      onFilter: (value: string, data: DataRow) =>
        data.latestLogin[0]?.toLowerCase() === value,
    },
  ];
  return (
    <>
      <Flex>
        <Tabs
          value={onlyDisplayTopFive ? 1 : 2}
          sx={{ textTransform: "none" }}
          onChange={handleChange}
          aria-label="tabs"
        >
          <Tab value={1} label="Top 5 Users" />
          <Tab value={2} label="All Users" />
        </Tabs>
      </Flex>
      <Table
        id={"Table Title"}
        data={onlyDisplayTopFive ? data.slice(0, 5) : rawData}
        columns={columns}
        showSearchFilter
        showPagination={!onlyDisplayTopFive}
      />
    </>
  );
}
