import React from "react";
import styled from "styled-components";
import { Flex } from "../Styles/Styles";

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--neutralBlack);
`;

const Subtitle = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--gray);
`;

const Eyebrow = styled.div`
  background: var(--neutralWhite);
  border-radius: 0.5rem;
  font-size: 2rem;
  padding: 1rem;
  align-content: center;
  color: var(--primary);
  background: var(--blue50);
`;

const Header = styled.div<{ $titlePaddingBottom?: number }>`
  align-content: center;
  padding-bottom: ${({ $titlePaddingBottom }) =>
    $titlePaddingBottom ? `${$titlePaddingBottom}px` : "0"};
`;

const GraphWrapper = styled.div<{
  $graphHeight?: string;
  $flex: number;
}>`
  height: ${({ $graphHeight }) =>
    $graphHeight ? `${$graphHeight}` : "17.5rem"};
  flex: ${({ $flex }) => $flex};
`;

type CardProps = {
  children?: React.ReactNode;
  $secondary?: boolean;
  title: string;
  subtitle?: string;
  eyebrow?: string | React.ReactNode;
  graphHeight?: string;
  width?: number;
  flex?: number;
  paddingUp?: number;
  titlePaddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

export const CardWrapper = styled.div<{
  $secondary?: boolean;
  $width?: number;
  $paddingUp?: number;
  $titlePaddingBottom?: number;
  $paddingLeft?: number;
  $paddingRight?: number;
}>`
  background: var(--neutralWhite);
  border-radius: 0.875rem;
  box-shadow: 0 4px 9px -1px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  width: fit-content;
  justify-content: space-between;
  align-content: center;
  width: ${({ $width }) => ($width ? `${$width}px` : "max(100%, 27.625rem);")};

  &:hover {
    box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.4);
  }

  ${(props) =>
    props.$secondary &&
    `
        background: var(--neutral300);
        color: var(--neutralBlack);
        width: min(100%, 255px);
        height: 4.125rem;

        ${Eyebrow} {
          color: var(--neutralBlack);
          background: var(--neutral50);
        }

        ${Title} {
          color: var(--neutralBlack);
        }
    `}
`;

const Card: React.FC<CardProps> = ({
  eyebrow,
  $secondary,
  title,
  subtitle,
  children,
  graphHeight,
  flex = 1,
  titlePaddingBottom,
  width,
}) => {
  return (
    <CardWrapper $secondary={$secondary} $width={width}>
      <Flex $justifyContent="space-between">
        <Header $titlePaddingBottom={titlePaddingBottom}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Header>
        {React.isValidElement(eyebrow)
          ? eyebrow
          : eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
      </Flex>
      {children && (
        <GraphWrapper $flex={flex} $graphHeight={graphHeight}>
          {children}
        </GraphWrapper>
      )}
    </CardWrapper>
  );
};

export default Card;
