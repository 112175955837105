import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CatalogSubHeading from "./CatalogSubHeading";

export const CatalogBenefits: React.FC<{
  title?: string;
  listItems: {
    icon: React.ReactNode;
    heading?: string;
    description?: string;
  }[];
}> = ({ title = "Benefits", listItems }) => {
  const renderListItem = () => {
    return listItems.map((item) => {
      return (
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {item.icon}

          <Typography
            variant="h6"
            style={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {item.heading}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {item.description}
          </Typography>
        </div>
      );
    });
  };
  return (
    <>
      <Grid container direction="column" justifyContent="center" gap={3}>
        <CatalogSubHeading title={title} />
        <Grid container spacing={2}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridRowGap: "2rem",
              gridColumnGap: "2rem",
              padding: "1rem",
            }}
          >
            {renderListItem()}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CatalogBenefits;

<Typography
  variant="h4"
  fontSize="2.125rem"
  fontWeight={600}
  component="div"
  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
></Typography>;
