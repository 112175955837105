import * as React from "react";
import { useNavigate } from "react-router-dom";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Button } from "@ssce/ui-shared-library";

const SightlineReport: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/ssnc.png"}
        title={"Sightline"}
        subTitle="SS&C Sightline, North America"
        contents={
          "SS&C Sightline is a self-serve, scalable web-based business intelligence (BI) platform designed to streamline data challenges and accelerate business value. It provides flexible, on-demand reporting and analytics, enabling businesses to make informed decisions by eliminating rigid reporting systems and simplifying fragmented processes."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"Sightline"} instanceName={"SS&C Sightline"} />
    </>
  );
};
export default SightlineReport;
