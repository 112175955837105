import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import RiskthinkingOverView from "./RiskthinkingOverView";
import "./Riskthinking.css";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const CatalogRiskthinking: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/RiskthinkingAI.png"}
        title={"VELO Physical Risk Exposure Firm Level Reports & Data"}
        contents={
          "Climate-related physical risk reports and data for listed public companies and proprietary assets to enable a deeper understanding of your physical risk exposures.​"
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          id={"createInstance"}
          width="12rem"
          onClick={() => navigate("/public-catalog/riskthinking/provision")}
        >
          Create Instance
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Documentation" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <RiskthinkingOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "2.5rem", padding: 0 }}></TabPanel>
        <TabPanel
          value="3"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      {value === "1" && (
        <div className="createInstanceBtn">
          <Button
            variant="default"
            id={"createInstance"}
            width="12rem"
            onClick={() => navigate("/public-catalog/riskthinking/provision")}
          >
            Create Instance
          </Button>
          <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
            Getting Started
          </Button>
        </div>
      )}
    </>
  );
};
export default CatalogRiskthinking;
