import {
  BarChart as ReBarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { DAUData, LoginUserData } from "../Types/types";
import { formatDate } from "../Utils/formatDate";

type BarChartProps = {
  fetchedActiveUserData: DAUData[];
  fetchedLoginsData: LoginUserData[];
  loading: boolean;
  error: boolean;
  isHourly: boolean;
};

const arrOfTimes = [
  {
    label: "12am",
    id: "00",
  },
  { label: "1am", id: "01" },
  { label: "2am", id: "02" },
  { label: "3am", id: "03" },
  { label: "4am", id: "04" },
  { label: "5am", id: "05" },
  { label: "6am", id: "06" },
  { label: "7am", id: "07" },
  { label: "8am", id: "08" },
  { label: "9am", id: "09" },
  { label: "10am", id: "10" },
  { label: "11am", id: "11" },
  { label: "12pm", id: "12" },
  { label: "1pm", id: "13" },
  { label: "2pm", id: "14" },
  { label: "3pm", id: "15" },
  { label: "4pm", id: "16" },
  { label: "5pm", id: "17" },
  { label: "6pm", id: "18" },
  { label: "7pm", id: "19" },
  { label: "8pm", id: "20" },
  { label: "9pm", id: "21" },
  { label: "10pm", id: "22" },
  { label: "11pm", id: "23" },
];

const findLoginCount = (fetchedLoginsData: LoginUserData[], key: number) => {
  const arr: string[] = [];

  fetchedLoginsData.map((item) => {
    const hour = item.created_at.split("T");
    const firstHourIndex = hour[1]?.at(0) ?? "0";
    const secondHourIndex = hour[1]?.at(1) ?? "0";
    const hourCreated = firstHourIndex + secondHourIndex;
    return arr.push(hourCreated);
  });
  return arr.filter((item) => item === arrOfTimes[key]?.id).length;
};
export default function UniqueUsersBarChart({
  fetchedActiveUserData,
  fetchedLoginsData,
  loading,
  error = false,
  isHourly,
}: BarChartProps) {
  const newData = arrOfTimes?.map((_, key) => {
    return {
      name: arrOfTimes[key]?.label,
      logins: findLoginCount(fetchedLoginsData, key),
    };
  });

  if (error) return <p>Error!</p>;
  if (loading) return <p>Loading...</p>;

  const getDAUData = () => {
    if (!fetchedActiveUserData) return;
    const data = fetchedActiveUserData.map((item) => {
      return {
        name: formatDate(item.time_interval, "year"),
        logins: item.count,
      };
    });
    return data;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ReBarChart
          width={100}
          height={50}
          data={
            isHourly ? newData && newData.filter((user) => user) : getDAUData()
          }
          margin={{
            top: 5,
            left: 20,
            bottom: 100,
          }}
        >
          <XAxis dataKey="name" angle={-90} textAnchor="end" />
          <YAxis
            dataKey="logins"
            label={{
              value: "Logins",
              angle: -90,
              position: "insideLeft",
              offset: -10,

              fill: "#75909F",
              fontSize: "14px",
              fontFamily: "Arial, sans-serif",
              fontWeight: "light",
            }}
          />
          <Tooltip />
          <Bar
            dataKey="logins"
            fill="#0A85C7"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
            radius={[10, 10, 0, 0]}
          />
        </ReBarChart>
      </ResponsiveContainer>
    </>
  );
}
