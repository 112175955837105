import { useState, useEffect } from "react";
import {
  PieChart as RechartsPieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import styled from "styled-components";

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 17.188rem;
  width: 25rem;
`;

const COLORS = ["#0A85C7", "#33BDE9", "gray"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentToShow = (percent * 100).toFixed(0);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={"middle"}
      dominantBaseline="central"
    >
      {Number(percentToShow) > 4 ? `${percentToShow}%` : ""}
    </text>
  );
};

type UserObj = {
  created_at: string;
  login_type: string;
  timestamp: number;
  user: string;
};

type PieChartProps = {
  fetchedLoginsData: UserObj[];
};

const renderCustomLegend = (props: any) => {
  const { payload } = props;
  return (
    <ul style={{ textAlign: "left" }}>
      {payload?.map((entry, index) => (
        <li key={`item-${index}`} style={{ color: entry.color }}>
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

export default function SessionsByPlatformPieChart({
  fetchedLoginsData,
}: PieChartProps) {
  const [data, setData] = useState<{ name: string; value: number }[]>([]);
  useEffect(() => {
    if (!fetchedLoginsData) return;
    const isMarketplace = fetchedLoginsData.filter((userInfo: UserObj) => {
      return userInfo.login_type === "marketplace";
    });
    const isDatamesh = fetchedLoginsData.filter((userInfo: UserObj) => {
      return userInfo.login_type === "data_mesh";
    });

    setData([
      {
        name: "Marketplace",
        value: isMarketplace.length,
      },
      {
        name: "Data Mesh",
        value: isDatamesh.length,
      },
    ]);
  }, [fetchedLoginsData]);

  return (
    <GridItem>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsPieChart width={800} height={800}>
          <Pie
            data={data}
            cx="50%"
            cy="60%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={120}
            innerRadius={70}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            wrapperStyle={{
              position: "relative",
              top: "-200px",
              left: "325px",
              width: "200px",
            }}
            content={renderCustomLegend}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </GridItem>
  );
}
