import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./TaxBrightline.css";
import { useNavigate } from "react-router-dom";
import TaxBrightlineOverView from "./TaxBrightlineOverView";
import TaxBrightlineTechnicalSpecifications from "./TaxBrightlineTechnicalSpecifications";
import TaxBrightlinePlansAndFeatures from "./TaxBrightlinePlansAndFeatures";
import SSNCImage from "../../../../assets/ssnc.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const TaxBrightline: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={SSNCImage}
        title={"SS&C Tax Brightline"}
        contents={
          "SS&C Tax Brightline is a comprehensive, technology-powered solution designed to simplify tax compliance, reporting, and analytics for fund managers. By providing real-time data and analytics, it enables managers to optimize their investment strategies and manage their tax exposure year-round, ensuring compliance in a constantly evolving tax landscape."
        }
        tags={[
          {
            label: "Tax Compliance",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() =>
            navigate("/public-catalog/ss&c-tax-brightline/provision")
          }
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TaxBrightlineOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TaxBrightlinePlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TaxBrightlineTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() =>
            navigate("/public-catalog/ss&c-tax-brightline/provision")
          }
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default TaxBrightline;
