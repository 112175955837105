import { Button } from "@ssce/ui-shared-library";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styled from "styled-components";
import { tableauUrl } from "src/constants";
import ReportsTable from "../reportsTable";

const DummyReportsTabContainer = styled.div`
  display: flex;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
`;

export const DummyReportsTab: React.FC<{
  title;
  reportTableName;
  isRiskThinking;
}> = ({ title, reportTableName, isRiskThinking }) => {
  console.log("Tableau", `${tableauUrl}`);
  const openDashboard = () => {
    const url =
      title === "Tableau"
        ? `${tableauUrl}`
        : "http://awa-dev-05.ssnc-corp.cloud:8080";
    window.open(url, "_blank");
  };

  if (reportTableName)
    return (
      <ReportsTable
        title={reportTableName}
        newReports={true}
        showDashBoardButton={!isRiskThinking}
      />
    );

  return (
    <DummyReportsTabContainer>
      {title == "Tableau" && (
        <Button
          variant="default"
          id="openRiskDashboard"
          onClick={openDashboard}
        >
          Open {title} Dashboard <OpenInNewIcon />
        </Button>
      )}
    </DummyReportsTabContainer>
  );
};
