import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { LoginUserData, ActiveUserData } from "../Types/types";
import dayjs from "dayjs";

interface DayData {
  x: string;
  y: number;
}

interface SeriesData {
  name: string;
  data: DayData[];
}

interface AccumulatedData {
  [key: number]: DayData[];
}

type FormattedHourMinuteData = {
  hourCreated: string;
  minuteCreated: string;
};

const getDayOfWeek = (day: number) => {
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  return days[day];
};

const hourToString = (hour: string) => {
  switch (hour) {
    case "00":
      return "12:00am";
    case "01":
      return "1:00am";
    case "02":
      return "2:00am";
    case "03":
      return "3:00am";
    case "04":
      return "4:00am";
    case "05":
      return "5:00am";
    case "06":
      return "6:00am";
    case "07":
      return "7:00am";
    case "08":
      return "8:00am";
    case "09":
      return "9:00am";
    case "10":
      return "10:00am";
    case "11":
      return "11:00am";
    case "12":
      return "12:00pm";
    case "13":
      return "1:00pm";
    case "14":
      return "2:00pm";
    case "15":
      return "3:00pm";
    case "16":
      return "4:00pm";
    case "17":
      return "5:00pm";
    case "18":
      return "6:00pm";
    case "19":
      return "7:00pm";
    case "20":
      return "8:00pm";
    case "21":
      return "9:00pm";
    case "22":
      return "10:00pm";
    case "23":
      return "11:00pm";
    case "24":
      return "12:00am";
  }
  return "00:00am";
};

const processFetchedDailyData = (data: LoginUserData[]) => {
  const formatFetchedData = () => {
    const arr: FormattedHourMinuteData[] = [];

    data.forEach((item) => {
      const date = dayjs(item.created_at);
      const hourCreated = date.format("HH");
      const minuteCreated = date.format("mm");

      arr.push({
        hourCreated: hourToString(hourCreated),
        minuteCreated,
      });
    });
    return arr;
  };

  const getFilteredData = (
    minMinute: number,
    maxMinute: number,
    hour: string,
  ) => {
    const filteredData = formatFetchedData()
      .filter(
        (item) =>
          Number(item.minuteCreated) >= minMinute &&
          Number(item.minuteCreated) <= maxMinute,
      )
      .filter((item) => item.hourCreated === hour);
    return filteredData.length;
  };

  const seriesData: SeriesData[] = [
    {
      name: "5",
      data: [
        { x: "1:00pm", y: getFilteredData(0, 5, "1:00pm") },
        { x: "2:00pm", y: getFilteredData(0, 5, "2:00pm") },
        { x: "3:00pm", y: getFilteredData(0, 5, "3:00pm") },
        { x: "4:00pm", y: getFilteredData(0, 5, "4:00pm") },
        { x: "5:00pm", y: getFilteredData(0, 5, "5:00pm") },
        { x: "6:00pm", y: getFilteredData(0, 5, "6:00pm") },
        { x: "7:00pm", y: getFilteredData(0, 5, "7:00pm") },
        { x: "8:00pm", y: getFilteredData(0, 5, "8:00pm") },
        { x: "9:00pm", y: getFilteredData(0, 5, "9:00pm") },
        { x: "10:00pm", y: getFilteredData(0, 5, "10:00pm") },
        { x: "11:00pm", y: getFilteredData(0, 5, "11:00pm") },
        { x: "12:00pm", y: getFilteredData(0, 5, "12:00pm") },
        { x: "1:00am", y: getFilteredData(0, 5, "1:00am") },
        { x: "2:00am", y: getFilteredData(0, 5, "2:00am") },
        { x: "3:00am", y: getFilteredData(0, 5, "3:00am") },
        { x: "4:00am", y: getFilteredData(0, 5, "4:00am") },
        { x: "5:00am", y: getFilteredData(0, 5, "5:00am") },
        { x: "6:00am", y: getFilteredData(0, 5, "6:00am") },
        { x: "7:00am", y: getFilteredData(0, 5, "7:00am") },
        { x: "8:00am", y: getFilteredData(0, 5, "8:00am") },
        { x: "9:00am", y: getFilteredData(0, 5, "9:00am") },
        { x: "10:00am", y: getFilteredData(0, 5, "10:00am") },
        { x: "11:00am", y: getFilteredData(0, 5, "11:00am") },
        { x: "12:00am", y: getFilteredData(0, 5, "12:00am") },
      ],
    },
    {
      name: "15",
      data: [
        { x: "1:00pm", y: getFilteredData(5, 15, "1:00pm") },
        { x: "2:00pm", y: getFilteredData(5, 15, "2:00pm") },
        { x: "3:00pm", y: getFilteredData(5, 15, "3:00pm") },
        { x: "4:00pm", y: getFilteredData(5, 15, "4:00pm") },
        { x: "5:00pm", y: getFilteredData(5, 15, "5:00pm") },
        { x: "6:00pm", y: getFilteredData(5, 15, "6:00pm") },
        { x: "7:00pm", y: getFilteredData(5, 15, "7:00pm") },
        { x: "8:00pm", y: getFilteredData(5, 15, "8:00pm") },
        { x: "9:00pm", y: getFilteredData(5, 15, "9:00pm") },
        { x: "10:00pm", y: getFilteredData(5, 15, "10:00pm") },
        { x: "11:00pm", y: getFilteredData(5, 15, "11:00pm") },
        { x: "12:00pm", y: getFilteredData(5, 15, "12:00pm") },
        { x: "1:00am", y: getFilteredData(5, 15, "1:00am") },
        { x: "2:00am", y: getFilteredData(5, 15, "2:00am") },
        { x: "3:00am", y: getFilteredData(5, 15, "3:00am") },
        { x: "4:00am", y: getFilteredData(5, 15, "4:00am") },
        { x: "5:00am", y: getFilteredData(5, 15, "5:00am") },
        { x: "6:00am", y: getFilteredData(5, 15, "6:00am") },
        { x: "7:00am", y: getFilteredData(5, 15, "7:00am") },
        { x: "8:00am", y: getFilteredData(5, 15, "8:00am") },
        { x: "9:00am", y: getFilteredData(5, 15, "9:00am") },
        { x: "10:00am", y: getFilteredData(5, 15, "10:00am") },
        { x: "11:00am", y: getFilteredData(5, 15, "11:00am") },
        { x: "12:00am", y: getFilteredData(5, 15, "12:00am") },
      ],
    },
    {
      name: "30",
      data: [
        { x: "1:00pm", y: getFilteredData(15, 30, "1:00pm") },
        { x: "2:00pm", y: getFilteredData(15, 30, "2:00pm") },
        { x: "3:00pm", y: getFilteredData(15, 30, "3:00pm") },
        { x: "4:00pm", y: getFilteredData(15, 30, "4:00pm") },
        { x: "5:00pm", y: getFilteredData(15, 30, "5:00pm") },
        { x: "6:00pm", y: getFilteredData(15, 30, "6:00pm") },
        { x: "7:00pm", y: getFilteredData(15, 30, "7:00pm") },
        { x: "8:00pm", y: getFilteredData(15, 30, "8:00pm") },
        { x: "9:00pm", y: getFilteredData(15, 30, "9:00pm") },
        { x: "10:00pm", y: getFilteredData(15, 30, "10:00pm") },
        { x: "11:00pm", y: getFilteredData(15, 30, "11:00pm") },
        { x: "12:00pm", y: getFilteredData(15, 30, "12:00pm") },
        { x: "1:00am", y: getFilteredData(15, 30, "1:00am") },
        { x: "2:00am", y: getFilteredData(15, 30, "2:00am") },
        { x: "3:00am", y: getFilteredData(15, 30, "3:00am") },
        { x: "4:00am", y: getFilteredData(15, 30, "4:00am") },
        { x: "5:00am", y: getFilteredData(15, 30, "5:00am") },
        { x: "6:00am", y: getFilteredData(15, 30, "6:00am") },
        { x: "7:00am", y: getFilteredData(15, 30, "7:00am") },
        { x: "8:00am", y: getFilteredData(15, 30, "8:00am") },
        { x: "9:00am", y: getFilteredData(15, 30, "9:00am") },
        { x: "10:00am", y: getFilteredData(15, 30, "10:00am") },
        { x: "11:00am", y: getFilteredData(15, 30, "11:00am") },
        { x: "12:00am", y: getFilteredData(15, 30, "12:00am") },
      ],
    },
    {
      name: "45",
      data: [
        { x: "1:00pm", y: getFilteredData(30, 45, "1:00pm") },
        { x: "2:00pm", y: getFilteredData(30, 45, "2:00pm") },
        { x: "3:00pm", y: getFilteredData(30, 45, "3:00pm") },
        { x: "4:00pm", y: getFilteredData(30, 45, "4:00pm") },
        { x: "5:00pm", y: getFilteredData(30, 45, "5:00pm") },
        { x: "6:00pm", y: getFilteredData(30, 45, "6:00pm") },
        { x: "7:00pm", y: getFilteredData(30, 45, "7:00pm") },
        { x: "8:00pm", y: getFilteredData(30, 45, "8:00pm") },
        { x: "9:00pm", y: getFilteredData(30, 45, "9:00pm") },
        { x: "10:00pm", y: getFilteredData(30, 45, "10:00pm") },
        { x: "11:00pm", y: getFilteredData(30, 45, "11:00pm") },
        { x: "12:00pm", y: getFilteredData(30, 45, "12:00pm") },
        { x: "1:00am", y: getFilteredData(30, 45, "1:00am") },
        { x: "2:00am", y: getFilteredData(30, 45, "2:00am") },
        { x: "3:00am", y: getFilteredData(30, 45, "3:00am") },
        { x: "4:00am", y: getFilteredData(30, 45, "4:00am") },
        { x: "5:00am", y: getFilteredData(30, 45, "5:00am") },
        { x: "6:00am", y: getFilteredData(30, 45, "6:00am") },
        { x: "7:00am", y: getFilteredData(30, 45, "7:00am") },
        { x: "8:00am", y: getFilteredData(30, 45, "8:00am") },
        { x: "9:00am", y: getFilteredData(30, 45, "9:00am") },
        { x: "10:00am", y: getFilteredData(30, 45, "10:00am") },
        { x: "11:00am", y: getFilteredData(30, 45, "11:00am") },
        { x: "12:00am", y: getFilteredData(30, 45, "12:00am") },
      ],
    },
    {
      name: "60",
      data: [
        { x: "1:00pm", y: getFilteredData(45, 60, "1:00pm") },
        { x: "2:00pm", y: getFilteredData(45, 60, "2:00pm") },
        { x: "3:00pm", y: getFilteredData(45, 60, "3:00pm") },
        { x: "4:00pm", y: getFilteredData(45, 60, "4:00pm") },
        { x: "5:00pm", y: getFilteredData(45, 60, "5:00pm") },
        { x: "6:00pm", y: getFilteredData(45, 60, "6:00pm") },
        { x: "7:00pm", y: getFilteredData(45, 60, "7:00pm") },
        { x: "8:00pm", y: getFilteredData(45, 60, "8:00pm") },
        { x: "9:00pm", y: getFilteredData(45, 60, "9:00pm") },
        { x: "10:00pm", y: getFilteredData(45, 60, "10:00pm") },
        { x: "11:00pm", y: getFilteredData(45, 60, "11:00pm") },
        { x: "12:00pm", y: getFilteredData(45, 60, "12:00pm") },
        { x: "1:00am", y: getFilteredData(45, 60, "1:00am") },
        { x: "2:00am", y: getFilteredData(45, 60, "2:00am") },
        { x: "3:00am", y: getFilteredData(45, 60, "3:00am") },
        { x: "4:00am", y: getFilteredData(45, 60, "4:00am") },
        { x: "5:00am", y: getFilteredData(45, 60, "5:00am") },
        { x: "6:00am", y: getFilteredData(45, 60, "6:00am") },
        { x: "7:00am", y: getFilteredData(45, 60, "7:00am") },
        { x: "8:00am", y: getFilteredData(45, 60, "8:00am") },
        { x: "9:00am", y: getFilteredData(45, 60, "9:00am") },
        { x: "10:00am", y: getFilteredData(45, 60, "10:00am") },
        { x: "11:00am", y: getFilteredData(45, 60, "11:00am") },
        { x: "12:00am", y: getFilteredData(45, 60, "12:00am") },
      ],
    },
  ];
  return seriesData ?? [];
};

function getWeekNumber(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

const processFetchedData = (data: ActiveUserData[]) => {
  return data.reduce((acc: AccumulatedData, { time_interval, count = 0 }) => {
    const date = new Date(time_interval);
    const week = `Week ${getWeekNumber(date)}`;
    const dayOfWeek = date.getDay();

    let accDayOfWeek: DayData[] = acc[dayOfWeek] ?? [{ x: week, y: count }];

    if (!accDayOfWeek) {
      accDayOfWeek = [{ x: week, y: count }];
    }

    const weekIndex = accDayOfWeek.findIndex((item) => item.x === week);
    if (weekIndex === -1) {
      accDayOfWeek.push({ x: week, y: count });
    } else {
      if (accDayOfWeek[weekIndex]?.y) {
        let accDayOfWeekItem = accDayOfWeek[weekIndex] ?? { x: week, y: 0 };
        accDayOfWeekItem.y += count;
      }
    }

    return acc;
  }, {} as AccumulatedData);
};


type HeatmapProps = {
  fetchedLoginsData: LoginUserData[];
  fetchedHeatmapData: ActiveUserData[];
  error: boolean;
  loading: boolean;
};

const ApexChart = ({
  fetchedLoginsData,
  fetchedHeatmapData,
  error,
  loading,
}: HeatmapProps) => {
  const [series, setSeries] = useState<SeriesData[]>([]);
  useEffect(() => {
    if (fetchedLoginsData) {
      return setSeries(processFetchedDailyData(fetchedLoginsData));
    }

    const groupedData = processFetchedData(fetchedHeatmapData);
    const seriesData = Object.keys(groupedData).map((day) => ({
      name: getDayOfWeek(parseInt(day)) ?? "",
      data: groupedData[day] ?? [],
    }));

    setSeries(seriesData);
  }, [fetchedHeatmapData, fetchedLoginsData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error!</div>;

  return (
    <div>
      <ReactApexChart
        options={{
          chart: {
            height: 350,
            width: 300,
            type: "heatmap",
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#008FFB"],
          title: {
            text: "",
          },
          yaxis: {
            title: {
              text: "Minutes",
              style: {
                color: "#75909F",
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
                fontWeight: "light",
              },
            },
          },
        }}
        series={series}
        type="heatmap"
        height={350}
      />
    </div>
  );
};

export default ApexChart;
