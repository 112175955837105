import styled from 'styled-components';

export const Flex = styled.div<{
  $gap?: number;
  $paddingBottom?: number;
  $paddingTop?: number;
  $paddingLeft?: number;
  $paddingRight?: number;
  $justifyContent?: string;
}>`
  display: flex;
  gap: ${(props) => props.$gap || 0}px;
  padding-bottom: ${(props) => props.$paddingBottom || 0}px;
  padding-top: ${(props) => props.$paddingTop || 0}px;
  padding-left: ${(props) => props.$paddingLeft || 0}px;
  padding-right: ${(props) => props.$paddingRight || 0}px;
  justify-content: ${(props) => props.$justifyContent || 'none'};
`;

export const YAxisLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: gray;
`;

export const Header = styled.h1`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button<{
  $secondary?: boolean;
  $icon?: boolean;
  $isActive?: boolean;
}>`
  background: ${(props) =>
    props.$isActive ? "var(--azure200)" : "var(--neutral200)"};
  color: ${(props) =>
    props.$isActive ? "var(--blue800)" : "var(--neutral800)"};
  font-weight: 700;
  border: none;
  width: fit-content;
  border-radius: 0.25rem;
  cursor: pointer;
  letter-spacing: 1.25px;
  padding: 0.5rem 1rem;
  border: 0;
  height: 2.625rem;

  &:active {
    transform: scale(1.1);
  }

  ${(props) =>
    props.$secondary &&
    `
        background: var(--primary);
        color: var(--neutralWhite);
        border: 0;

        svg {
          vertical-align: middle;
        }
    `}

  ${(props) =>
    props.$icon &&
    `
        background: var(--neutralWhite);
        color: var(--primary);
        border: 4px solid var(--primary);
        padding: 0 0.5rem;
        svg {
          vertical-align: middle;
        }
    `}
`;
