import React from "react";
import Grid from "../../subcomponents/Grid/Grid";
import { ServiceCard, Chip } from "@ssce/ui-shared-library";
import styled from "styled-components";

const MyServicesCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 28.18rem;
  line-height: 0.5rem;
`;

interface MyServicesCardsSectionProps {
  width?: string;
}
const MyServicesCardsSection = styled.div<MyServicesCardsSectionProps>`
  max-width: 13.59375rem;
  width: ${(props) => props.width || "auto"};
`;

const MyServicesCardsLabel = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--neutral700);
`;
interface MyServicesCardsValueProps {
  isNA?: boolean;
  region?: string;
}

const MyServicesCardsValue = styled.p<MyServicesCardsValueProps>`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => (props.isNA ? "#90A8B5" : "initial")};
  text-transform: ${(props) =>
    props.region?.toLowerCase() === "us" ? "uppercase" : "none"};
`;

export type ItemProps = {
  applicationName: string;
  brand: string;
  businessUnit: string;
  labels: { banner: string };
  catalogId: string;
  catalogType: string;
  instanceId: string;
  instanceName: string;
  logo: string;
  majorVersion: number;
  minorVersion: number;
  serviceName: string;
  region: string;
  tags: { function: string; industry: string; serviceType: string };
  pricingPlans: string;
  plan: string;
};

export type MyServicesListViewProps = {
  filteredItems: ItemProps[];
  handleItemSelect: (item: any) => void;
};

const MyServicesListView: React.FC<MyServicesListViewProps> = ({
  filteredItems,
  handleItemSelect,
}) => {
  return (
    <Grid minMax="25rem">
      {filteredItems.map((item: ItemProps, i: number) => {
        const tagChips = () => [
          <Chip
            tag="function"
            width="none"
            key={`${item.instanceName}function`}
          >
            {item.tags.function}
          </Chip>,
          <Chip
            tag="industry"
            width="none"
            key={`${item.instanceName}industry`}
          >
            {item.tags.industry}
          </Chip>,
          <Chip
            tag="service type"
            width="none"
            key={`${item.instanceName}serviceType`}
          >
            {item.tags.serviceType}
          </Chip>,
        ];

        const isNA = !item.plan || item.plan === "N/A";
        return (
          <div data-testid={"serviceCard"} key={`${i}${item.instanceId}`}>
            <ServiceCard
              id={`${i}${item.catalogId}`}
              title={item.instanceName}
              logo={item.logo}
              subtitle={"Ver. 1.0"}
              tags={tagChips()}
              onClick={() => handleItemSelect(item)}
            >
              <MyServicesCardsContainer>
                <MyServicesCardsSection width="50%">
                  <MyServicesCardsLabel>Service Name</MyServicesCardsLabel>
                  <MyServicesCardsValue>
                    {item.serviceName}
                  </MyServicesCardsValue>
                </MyServicesCardsSection>

                <MyServicesCardsSection width="25%">
                  <MyServicesCardsLabel>Region</MyServicesCardsLabel>
                  <MyServicesCardsValue region={item.region}>
                    {item.region}
                  </MyServicesCardsValue>
                </MyServicesCardsSection>

                <MyServicesCardsSection>
                  <MyServicesCardsLabel>Plan</MyServicesCardsLabel>
                  <MyServicesCardsValue isNA={isNA}>
                    {item.plan || "N/A"}
                  </MyServicesCardsValue>
                </MyServicesCardsSection>
              </MyServicesCardsContainer>
            </ServiceCard>
          </div>
        );
      })}
    </Grid>
  );
};

export default MyServicesListView;
