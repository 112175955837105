import CardTravelTwoToneIcon from "@mui/icons-material/CardTravelTwoTone";
import LanTwoToneIcon from "@mui/icons-material/LanTwoTone";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import SettingsIcon from "@mui/icons-material/Settings";
import TopicTwoToneIcon from "@mui/icons-material/TopicTwoTone";
import DashboardTwoTone from "@mui/icons-material/DashboardTwoTone";
import InsightsIcon from "@mui/icons-material/Insights";
import everywhere from "../../../assets/everywhere.png";
import { useSelector } from "react-redux";
import NavItem from "./NavItem";
import { PERMISSION_LIST } from "../../../constants";
import { FeatureToggle } from "@ssce/ui-shared-library";
import {
  StyledImage,
  StyledNav,
  StyledExpandIcon,
  StyledNavWidth,
} from "./Nav.styles";

const NAV_ITEMS = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: DashboardTwoTone,
    subMenu: [{ link: "/dashboard", title: "Dashboard" }],
  },
  {
    title: "My Services",
    to: "/my-services",
    icon: CardTravelTwoToneIcon,
    subMenu: [{ link: "/my-services", title: "My Services" }],
  },
  {
    title: "Catalog",
    icon: TopicTwoToneIcon,
    to: "/public-catalog",
    subMenu: [
      { link: "/public-catalog", title: "Public Catalog", parent: "Catalog" },
      {
        link: "/private-api-catalog",
        title: "Private API Catalog",
        parent: "Catalog",
      },
      { link: "/digital-workers", title: "Digital Workers", parent: "Catalog" },
    ],
  },
  {
    title: "Workflows",
    to: "/workflow-catalog",
    icon: LanTwoToneIcon,
    subMenu: [
      { link: "/workflow-catalog", title: "Workflow Catalog" },
      { link: "/my-workflows", title: "My Workflows" },
    ],
  },
  {
    title: "Billing",
    to: "/billing",
    icon: PaymentTwoToneIcon,
    subMenu: [{ link: "/billing", title: "Billing" }],
  },
  {
    title: "Documentation",
    to: "/documentation",
    icon: InsertDriveFileTwoToneIcon,
    subMenu: [{ link: "/documentation", title: "Documentation" }],
  },
  {
    title: "Settings",
    to: "/user-access-management",
    icon: SettingsIcon,
    subMenu: [
      {
        link: "/user-access-management",
        title: "User Access Management",
        parent: "Settings",
      },
      {
        link: "/manage-listings",
        title: "Manage Listings",
        parent: "Settings",
      },
      {
        link: "/apikeys",
        title: "Api Keys",
        parent: "Settings",
        permission: PERMISSION_LIST.API_KEYS_READ,
      },
    ],
  },
];
type NavProps = {
  activeSubMenuItem: string;
  setActiveSubMenuItem: (arg0: string) => void;
  activeMenuItem: string;
  setActiveMenuItem: (arg0: string) => void;
  handleBreadCrumbNickName: (name: string) => void;
  toggleSideNav: () => void;
  minimized: boolean;
};

const Nav = ({
  activeSubMenuItem,
  setActiveSubMenuItem,
  activeMenuItem,
  setActiveMenuItem,
  handleBreadCrumbNickName,
  toggleSideNav,
  minimized,
}: NavProps) => {
  const userManagement = useSelector(
    (_state: any) => _state.userManagement.userInfo,
  );

  return (
    <>
      <StyledNavWidth minimized={minimized} />
      <StyledNav minimized={minimized}>
        <StyledExpandIcon
          minimized={minimized}
          onClick={() => {
            toggleSideNav();
          }}
        />
        <StyledImage
          minimized={minimized}
          src={everywhere}
          alt="SS&C Everywhere"
        />
        {NAV_ITEMS.map((item) => (
          <NavItem
            item={item}
            minimized={minimized}
            activeSubMenuItem={activeSubMenuItem}
            setActiveSubMenuItem={setActiveSubMenuItem}
            activeMenuItem={activeMenuItem}
            setActiveMenuItem={setActiveMenuItem}
            handleBreadCrumbNickName={handleBreadCrumbNickName}
          />
        ))}
        <FeatureToggle
          feature={
            userManagement.organisation === "Miracle Capital"
              ? "authorizedUser"
              : "newFeatureInBeta"
          }
        >
          <NavItem
            item={{
              title: "Analytics",
              to: "/Analytics",
              icon: InsightsIcon,
              subMenu: [{ link: "/analytics", title: "Analytics" }],
            }}
            minimized={minimized}
            activeSubMenuItem={activeSubMenuItem}
            setActiveSubMenuItem={setActiveSubMenuItem}
            activeMenuItem={activeMenuItem}
            setActiveMenuItem={setActiveMenuItem}
            handleBreadCrumbNickName={handleBreadCrumbNickName}
          />
        </FeatureToggle>
      </StyledNav>
    </>
  );
};

export default Nav;
